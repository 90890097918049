<template>
  <div class="tab-box hidden-xs">
    <div class="tab-item" :class="{ active: ['privacyPolicy'].includes(name) }" @click="onTabbar('privacyPolicy')">隐私政策</div>
    <div class="tab-item" :class="{ active: ['userAgreement'].includes(name) }" @click="onTabbar('userAgreement')">用户协议</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: ''
    };
  },
  watch: {
    $route(val) {
      this.name = val.name;
    }
  },
  created() {
    this.name = this.$route.name;
  },
  methods: {
    onTabbar(pagename) {
      this.$router.push({ name: pagename });
    }
  }
};
</script>
<style lang="less">
.tab-box {
  width: 180px;
  float: left;
  .tab-item {
    width: 180px;
    height: 70px;
    line-height: 70px;
    font-size: 24px;
    color: #333333;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    &.active {
      background: #e84187;
      color: #fff;
      opacity: 0.4;
      border-radius: 35px;
    }
  }
}
@media screen and (max-width: 1441px) {
  .tab-box {
    margin-bottom: 50px;
    width: 100%;
    float: none;
    overflow: hidden;
    .tab-item {
      display: inline-block;
    }
  }
}
</style>
